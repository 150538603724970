<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n()

locale.value = localStorage.getItem('locale') || 'cn'
// 修改font-family
document.body.style.fontFamily = locale.value === 'cn' ? `"Microsoft YaHei"` : `"Syne", "Microsoft YaHei"`

if (locale.value === 'en') {
  document.body.classList.add('en-locale')
}

// 修改页面缩放
const screenWidth = window.innerWidth
if (screenWidth > 1200 && screenWidth < 1700) {
  const zoom = screenWidth / 1599
  // @ts-ignore
  document.body.style.zoom = zoom.toFixed(2)
}
</script>

<style lang="scss"></style>
