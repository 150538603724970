export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"name":"keywords","content":"信息技术咨询、教育咨询服务、网络安全信息咨询、思科、华为和深信服"},{"name":"description","content":"广州飞天信息科技有限公司成立于2019年03月19日，位于广州天河区，总面积约为300平方，包括办公区域和培训室。 我们公司主要专注于信息技术咨询服务、教育咨询服务和网络安全信息咨询。我们致力于优质网络人才培养。目前的主要课程内容涵盖了思科、华为和深信服等主流网络厂商的技术。 我们的客户来源包含社招、机构合作以及高校合作等。我们拥有专业的客户经理、讲师和教学班主任，并采用线上、线下相结合的授课方式。截至2023年9月，我们的国内事业部共培训了2100名学员，海外事业部共培训了1800名学员。我们在海内外思科安全和国内华为安全的IT认证培训方面名列前茅！ 同时，为了提高学员的学习效率和考试通过率，并让他们能够将所学知识应用到实践中，我们按照官方认证课程大纲进行1:1的实验环境部署，并将其托管于电信和移动的五星级IDC机房。我们采用国内、国际多出口的链路方式，为学员提供最优质的在线学习服务。 我们公司拥有一支具有开拓创新精神、扎实肯干、热情真诚的优秀团队。我们秉承着用最专业的人做最专业的事的理念，不断汲取知识、开拓视野并创新理念！"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[{"children":"JavaScript is required"}],"htmlAttrs":{"lang":"zh-cmn-Hans"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = true