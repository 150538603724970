import { default as introductioneZXPCN6qyEMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/about/introduction.vue?macro=true";
import { default as partnersIeaovmfylIMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/about/partners.vue?macro=true";
import { default as teamI80AotmOmGMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/about/team.vue?macro=true";
import { default as companysujiD0AOS0Meta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/company.vue?macro=true";
import { default as channel5kI0qKpJi2Meta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/cooperate/channel.vue?macro=true";
import { default as ecologyhCSOSuMrIJMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/cooperate/ecology.vue?macro=true";
import { default as _91type_93crqemB2pRPMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/courses/[type].vue?macro=true";
import { default as detailNb5pCkPwZbMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/courses/detail.vue?macro=true";
import { default as facilitiesvUW1TbOro0Meta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/facilities.vue?macro=true";
import { default as indexXj2LAaDB3wMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/index.vue?macro=true";
import { default as detail3MATvNXV4SMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/information/detail.vue?macro=true";
import { default as indexgsOvbaXOPgMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/information/index.vue?macro=true";
import { default as authentication8D2t4w4Zu1Meta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/authentication.vue?macro=true";
import { default as detailk62i3cHmIUMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/detail.vue?macro=true";
import { default as listCj7uYIcmWfMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/list.vue?macro=true";
import { default as recruitmentPfvclD02M2Meta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/recruitment.vue?macro=true";
import { default as search0lEoRJY9IZMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/search.vue?macro=true";
import { default as teamT5paJPxHeQMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/team.vue?macro=true";
export default [
  {
    name: introductioneZXPCN6qyEMeta?.name ?? "about-introduction",
    path: introductioneZXPCN6qyEMeta?.path ?? "/about/introduction",
    meta: introductioneZXPCN6qyEMeta || {},
    alias: introductioneZXPCN6qyEMeta?.alias || [],
    redirect: introductioneZXPCN6qyEMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/about/introduction.vue").then(m => m.default || m)
  },
  {
    name: partnersIeaovmfylIMeta?.name ?? "about-partners",
    path: partnersIeaovmfylIMeta?.path ?? "/about/partners",
    meta: partnersIeaovmfylIMeta || {},
    alias: partnersIeaovmfylIMeta?.alias || [],
    redirect: partnersIeaovmfylIMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: teamI80AotmOmGMeta?.name ?? "about-team",
    path: teamI80AotmOmGMeta?.path ?? "/about/team",
    meta: teamI80AotmOmGMeta || {},
    alias: teamI80AotmOmGMeta?.alias || [],
    redirect: teamI80AotmOmGMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: companysujiD0AOS0Meta?.name ?? "company",
    path: companysujiD0AOS0Meta?.path ?? "/company",
    meta: companysujiD0AOS0Meta || {},
    alias: companysujiD0AOS0Meta?.alias || [],
    redirect: companysujiD0AOS0Meta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/company.vue").then(m => m.default || m)
  },
  {
    name: channel5kI0qKpJi2Meta?.name ?? "cooperate-channel",
    path: channel5kI0qKpJi2Meta?.path ?? "/cooperate/channel",
    meta: channel5kI0qKpJi2Meta || {},
    alias: channel5kI0qKpJi2Meta?.alias || [],
    redirect: channel5kI0qKpJi2Meta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/cooperate/channel.vue").then(m => m.default || m)
  },
  {
    name: ecologyhCSOSuMrIJMeta?.name ?? "cooperate-ecology",
    path: ecologyhCSOSuMrIJMeta?.path ?? "/cooperate/ecology",
    meta: ecologyhCSOSuMrIJMeta || {},
    alias: ecologyhCSOSuMrIJMeta?.alias || [],
    redirect: ecologyhCSOSuMrIJMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/cooperate/ecology.vue").then(m => m.default || m)
  },
  {
    name: _91type_93crqemB2pRPMeta?.name ?? "courses-type",
    path: _91type_93crqemB2pRPMeta?.path ?? "/courses/:type()",
    meta: _91type_93crqemB2pRPMeta || {},
    alias: _91type_93crqemB2pRPMeta?.alias || [],
    redirect: _91type_93crqemB2pRPMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/courses/[type].vue").then(m => m.default || m)
  },
  {
    name: detailNb5pCkPwZbMeta?.name ?? "courses-detail",
    path: detailNb5pCkPwZbMeta?.path ?? "/courses/detail",
    meta: detailNb5pCkPwZbMeta || {},
    alias: detailNb5pCkPwZbMeta?.alias || [],
    redirect: detailNb5pCkPwZbMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/courses/detail.vue").then(m => m.default || m)
  },
  {
    name: facilitiesvUW1TbOro0Meta?.name ?? "facilities",
    path: facilitiesvUW1TbOro0Meta?.path ?? "/facilities",
    meta: facilitiesvUW1TbOro0Meta || {},
    alias: facilitiesvUW1TbOro0Meta?.alias || [],
    redirect: facilitiesvUW1TbOro0Meta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/facilities.vue").then(m => m.default || m)
  },
  {
    name: indexXj2LAaDB3wMeta?.name ?? "index",
    path: indexXj2LAaDB3wMeta?.path ?? "/",
    meta: indexXj2LAaDB3wMeta || {},
    alias: indexXj2LAaDB3wMeta?.alias || [],
    redirect: indexXj2LAaDB3wMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/index.vue").then(m => m.default || m)
  },
  {
    name: detail3MATvNXV4SMeta?.name ?? "information-detail",
    path: detail3MATvNXV4SMeta?.path ?? "/information/detail",
    meta: detail3MATvNXV4SMeta || {},
    alias: detail3MATvNXV4SMeta?.alias || [],
    redirect: detail3MATvNXV4SMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/information/detail.vue").then(m => m.default || m)
  },
  {
    name: indexgsOvbaXOPgMeta?.name ?? "information",
    path: indexgsOvbaXOPgMeta?.path ?? "/information",
    meta: indexgsOvbaXOPgMeta || {},
    alias: indexgsOvbaXOPgMeta?.alias || [],
    redirect: indexgsOvbaXOPgMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/information/index.vue").then(m => m.default || m)
  },
  {
    name: authentication8D2t4w4Zu1Meta?.name ?? "news-authentication",
    path: authentication8D2t4w4Zu1Meta?.path ?? "/news/authentication",
    meta: authentication8D2t4w4Zu1Meta || {},
    alias: authentication8D2t4w4Zu1Meta?.alias || [],
    redirect: authentication8D2t4w4Zu1Meta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/authentication.vue").then(m => m.default || m)
  },
  {
    name: detailk62i3cHmIUMeta?.name ?? "news-detail",
    path: detailk62i3cHmIUMeta?.path ?? "/news/detail",
    meta: detailk62i3cHmIUMeta || {},
    alias: detailk62i3cHmIUMeta?.alias || [],
    redirect: detailk62i3cHmIUMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/detail.vue").then(m => m.default || m)
  },
  {
    name: listCj7uYIcmWfMeta?.name ?? "news-list",
    path: listCj7uYIcmWfMeta?.path ?? "/news/list",
    meta: listCj7uYIcmWfMeta || {},
    alias: listCj7uYIcmWfMeta?.alias || [],
    redirect: listCj7uYIcmWfMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/list.vue").then(m => m.default || m)
  },
  {
    name: recruitmentPfvclD02M2Meta?.name ?? "news-recruitment",
    path: recruitmentPfvclD02M2Meta?.path ?? "/news/recruitment",
    meta: recruitmentPfvclD02M2Meta || {},
    alias: recruitmentPfvclD02M2Meta?.alias || [],
    redirect: recruitmentPfvclD02M2Meta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/news/recruitment.vue").then(m => m.default || m)
  },
  {
    name: search0lEoRJY9IZMeta?.name ?? "search",
    path: search0lEoRJY9IZMeta?.path ?? "/search",
    meta: search0lEoRJY9IZMeta || {},
    alias: search0lEoRJY9IZMeta?.alias || [],
    redirect: search0lEoRJY9IZMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/search.vue").then(m => m.default || m)
  },
  {
    name: teamT5paJPxHeQMeta?.name ?? "team",
    path: teamT5paJPxHeQMeta?.path ?? "/team",
    meta: teamT5paJPxHeQMeta || {},
    alias: teamT5paJPxHeQMeta?.alias || [],
    redirect: teamT5paJPxHeQMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/team.vue").then(m => m.default || m)
  }
]