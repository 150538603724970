import revive_payload_client_JOId7c6gJG from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2rwE0eqGDg from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CJH8BsdKFz from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UkeXlIAwrX from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_MzawwHY9ZP from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Z8kE5uqUuq from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/lion/Documents/work/outwork/website/nuxt3-template/.nuxt/components.plugin.mjs";
import prefetch_client_JXYYpVPjsZ from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_fyMlT8GLhy from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@nuxtjs+i18n@8.0.1_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import colors_sEEjiqWeWk from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@nuxt+ui@2.13.0_nuxt@3.10.0_vue@3.4.15/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_3dFO4wL2SD from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_gyX6QhdXAH from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/dayjs-nuxt@2.1.9/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import view_transitions_client_uGQnZ4iFYl from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_AzpcrCkVv4 from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_sass@1.70.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_9RNjyLX3El from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_JOId7c6gJG,
  unhead_2rwE0eqGDg,
  router_CJH8BsdKFz,
  payload_client_UkeXlIAwrX,
  check_outdated_build_client_MzawwHY9ZP,
  plugin_vue3_Z8kE5uqUuq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JXYYpVPjsZ,
  i18n_fyMlT8GLhy,
  colors_sEEjiqWeWk,
  plugin_client_3dFO4wL2SD,
  plugin_gyX6QhdXAH,
  view_transitions_client_uGQnZ4iFYl,
  chunk_reload_client_AzpcrCkVv4,
  plugin_9RNjyLX3El
]